@import './assets/styles/mixins';

.about_company {
  &__container {
    &_header {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1438px;
      margin-top: 29px;
      text-align: center;

      & video {
        max-height: unset;
        max-width: unset;
        width: 100%;
        height: 100%;
      }
    }

    &_main {
      margin: 71px auto 0;
      max-width: 835px;

      @include maxWidth($mobile-xl) {
        margin-top: 40px;
      }
    }
  }

  &__title {
    max-width: 1049px;
    margin: 0 auto 92px;
  }
}
