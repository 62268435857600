.default {
  opacity: 0;
}

.toHide {
  animation: toHide;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fromHide {
  animation: fromHide;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fromLeft {
  opacity: 0;
  animation: fromLeft;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.toLeft {
  opacity: 0;
  animation: toLeft;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fromBottom {
  opacity: 0;
  animation: fromBottom;
  animation-duration: 0.8s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes fromHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fromHide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fromLeft {
  from {
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes toLeft {
  from {
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fromBottom {
  from {
    transform: translateY(120%);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
