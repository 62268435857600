@import './assets/styles/mixins';

.section {
  max-width: unset;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    max-width: 838px;
  }

  &__title {
    margin-bottom: 100px;

    @include maxWidth($mobile-xl) {
      margin-bottom: 32px;
    }
  }

  &__address_block {
    display: flex;
    flex-direction: column;
    gap: 52px;

    @include maxWidth($mobile-xl) {
      gap: 32px;
    }
  }

  &__full_cycle_production {
    display: flex;
    flex-direction: column;
    gap: 90px;

    @include maxWidth($mobile-xl) {
      gap: 38px;
    }
  }

  &__multi_game {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: 142px;
      max-width: 708px;

      @include maxWidth($mobile-xl) {
        margin-bottom: 34px;
      }
    }
  }

  &__gaming_labs_block {
    position: relative;

    @include maxWidth($mobile-xl) {
      max-width: 78%;
      margin: 0 auto;
    }

    &__image_block {
      display: flex;
      justify-content: center;
    }
  }

  &__animation {
    &_title {
      opacity: 0.3;
      animation: title_2021_animation;
      animation-duration: 0.9s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    &_radial {
      position: relative;
      display: flex;
      justify-content: center;
      animation: radial_2021_animation;
      animation-duration: 0.9s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
      z-index: 1;
    }

    &_logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      animation: logo_2021_animation;
      animation-duration: 1s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }
  }
}

.game_categories_list {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 0 auto;
  gap: 40px;
  width: fit-content;

  @include maxWidth($tablet-s) {
    gap: 27px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    @include p1;

    display: flex;
    flex-direction: column;
    gap: 4px;
    height: max-content;
    // min-width: 104px;
    width: fit-content;
    text-align: center;
    transition: transform 0.15s ease;
    white-space: nowrap;
    gap: 20px;

    &:hover {
      transform: translateY(-8px);
    }

    @include maxWidth($mobile-xl) {
      min-width: 47px;
      height: max-content;
      width: max-content;
    }

    & p {
      color: rgba(255, 255, 255, 0.7);
    }

    &--active {
      opacity: 1;
    }

    &--unactive {
      opacity: 0.5;
    }

    &_animation {
      animation: game_categories_odd_item_animation;
      animation-duration: 1.1s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      z-index: 1;
      display: flex;
      justify-content: center;

      &:nth-child(2n) {
        animation-name: game_categories_even_item_animation;
      }
    }
  }

  & img {
    height: 52px;

    @include maxWidth($mobile-xl) {
      height: 42px;
    }
  }
}

@keyframes game_categories_odd_item_animation {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  35% {
    transform: translateY(0);
    opacity: 1;
  }

  60% {
    transform: translateY(-6px);
    opacity: 1;
  }

  85% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes game_categories_even_item_animation {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  30% {
    transform: translateY(-30px);
    opacity: 1;
  }

  55% {
    transform: translateY(15px);
    opacity: 1;
  }

  65% {
    transform: translateY(10px);
    opacity: 1;
  }

  75% {
    transform: translateY(-5px);
    opacity: 1;
  }

  85% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes logo_2021_animation {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes radial_2021_animation {
  from {
    transform: rotate(-90deg) scale(0);
    opacity: 0;
  }
  to {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

@keyframes title_2021_animation {
  from {
    transform: translateY(450px);
    opacity: 0.3;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.blue_title {
  color: $blue-3;
}

.full_height {
  margin: 150px 0;
}

.machines_image {
  aspect-ratio: 838 / 446;
  height: auto;
  width: 100%;
}
