@import './assets/styles/mixins';

.team_card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(33% - 33px);
  height: 100%;

  @include maxWidth($tablet-l) {
    width: calc(33% - 3vw);
  }

  @include maxWidth($mobile-xl) {
    width: calc(50% - 5vw);
  }

  &_info {
    position: relative;

    a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }
  }

  &__image {
    position: relative;
    border-radius: 22px;

    @include maxWidth($tablet-l) {
      border-radius: 18px;
    }

    @include maxWidth($tablet-s) {
      border-radius: 14px;
    }
  }

  &__name {
    margin-top: 36.5px;
    font-family: 'Monument Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    color: #ffffff;

    @include maxWidth($tablet-l) {
      margin-top: 28px;
    }

    @include maxWidth($tablet-m) {
      margin-top: 28px;
    }

    @include maxWidth($mobile-xl) {
      margin-top: 5.21px;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
    }

    @include maxWidth($mobile-l) {
      font-size: 16px;
    }

    @include maxWidth($mobile-m) {
      font-size: 14px;
    }
  }

  &__post {
    margin: 7px 0 13.76px;
    color: $gray-text;

    @include maxWidth($mobile-xl) {
      margin: 5px 0 10px;
    }
  }

  &__link {
    width: 43px;
    height: 43px;

    &:hover {
      svg rect {
        fill: #0041ff;
      }
    }

    @include maxWidth($mobile-xl) {
      width: 25px;
      height: 26px;
    }
  }
}
