@import './assets/styles/mixins';

.statistical_numbers {
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin-top: 146px;

  @include maxWidth($mobile-xl) {
    gap: 20px;
    margin-top: 49px;
  }

  &__title {
    text-align: center;

    @include maxWidth($mobile-m) {
      text-align: left;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1.1fr 1fr 1fr;

    // @include maxWidth($tablet-l) {
    // flex-flow: row wrap;
    //   justify-content: space-between;
    //   gap: 55px;
    // }

    @include maxWidth($mobile-l) {
      gap: 23px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 22px;

    @include maxWidth($mobile-l) {
      text-align: left;
      gap: 7px;
    }

    &_number {
      @include h1;

      @include maxWidth($mobile-l) {
        font-size: 30px;
      }
    }

    &_value {
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      color: $gray-medium;

      @include maxWidth($tablet-l) {
        font-size: 22px;
      }

      @include maxWidth($mobile-xl) {
        font-size: 18px;
      }

      @include maxWidth($mobile-l) {
        font-size: 13px;
        line-height: 140%;
      }
    }
  }
}
