@import './assets/styles/mixins';

.our_team {
  margin-top: 93px;

  @include maxWidth($mobile-xl) {
    margin-top: 58px;
  }

  &__tac {
    text-align: center;
  }

  &__cards {
    margin-top: 68px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;

    @include maxWidth($tablet-l) {
      gap: 4.5vw;
    }

    @include maxWidth($tablet-s) {
      margin-top: 37px;
    }

    @include maxWidth($mobile-xl) {
      justify-content: space-between;
      gap: 5vw;
    }
  }
}
