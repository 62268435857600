@import './assets/styles/mixins';

.our_history {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include maxWidth($tablet-l) {
    margin-top: 116px;
  }

  &__list {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 65px;

    @include maxWidth($tablet-l) {
      margin-top: 60px;
      gap: 55px;
    }

    @include maxWidth($mobile-l) {
      margin-top: 37px;
      gap: 30px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include maxWidth($tablet-l) {
      gap: 17px;
    }

    @include maxWidth($mobile-l) {
      gap: 6px;
    }

    &_descr {
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      color: $gray-medium;

      @include maxWidth($tablet-l) {
        font-size: 20px;
      }

      @include maxWidth($mobile-l) {
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
      }
    }
  }

  &__interval {
    color: $blue;
  }

  &__interval_title {
    position: sticky;
    top: 42px;
    color: $blue;
    padding: 40px 0 0;
    z-index: 10;
    margin-bottom: 79px;
    background-color: $black;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 171px;
      background-image: linear-gradient(180deg, #000000 46.05%, rgba(1, 1, 1, 0) 100%);
      z-index: -1;
    }
  }

  &__common_section {
    position: relative;
    max-width: 828px;
    display: flex;
    flex-direction: column;
  }

  &__blue_title {
    color: $blue-3;
  }
}
