@import './assets/styles/mixins';

.section {
  position: relative;
  max-width: 828px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 60px;
    gap: 37px;

    @include maxWidth($mobile-xl) {
      margin-top: 34px;
      gap: 16px;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    aspect-ratio: 256 / 357;
  }

  &__partners_block {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 57px;

    @include maxWidth($tablet-m) {
      gap: 32px;

      // &::after {
      //   content: '';
      //   position: absolute;
      //   inset: 0;
      //   background: radial-gradient(53.07% 105.54% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%);
      //   z-index: 10;
      // }
    }
  }

  &__collaborated_with {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 85px;

    @include maxWidth($mobile-xl) {
      gap: 34px;
    }

    & button {
      @include p1;

      width: fit-content;
      text-transform: none;
    }
  }
}

.full_height {
  margin: 150px 0;
}

.first_tape_animation {
  opacity: 1;
  animation: animation1;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-duration: 10s;

  @keyframes animation1 {
    from {
      transform: translateX(300px);
    }
    to {
      transform: translateX(0px);
    }
  }
}

.second_tape_animation {
  opacity: 1;
  animation: animation2;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-duration: 9.5s;

  @keyframes animation2 {
    from {
      transform: translateX(-300px);
    }
    to {
      transform: translateX(0px);
    }
  }
}

.third_tape_animation {
  opacity: 1;
  animation: animation3;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-duration: 9s;

  @keyframes animation3 {
    from {
      transform: translateX(300px);
    }
    to {
      transform: translateX(0px);
    }
  }
}
