@import './assets/styles/mixins';

.section {
  position: relative;
  max-width: 828px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__interval_title {
    position: sticky;
    top: 42px;
    color: $blue;
    padding: 40px 0 0;
    z-index: 10;
    margin-bottom: 79px;
    background-color: $black;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 171px;
      background-image: linear-gradient(180deg, #000000 46.05%, rgba(1, 1, 1, 0) 100%);
      z-index: -1;
    }

    @include maxWidth($mobile-xl) {
      margin-bottom: 32.5px;

      &::after {
        height: 90px;
      }
    }
  }
}

.full_height {
  margin: 150px 0;
}
