@import 'assets/styles/_mixins.scss';

.section {
  &__computers {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include maxWidth($tablet-s) {
      margin-top: 40px;
    }
  }

  &__left_computer {
    aspect-ratio: 402 / 321;
    height: auto;
  }

  &__right_computer {
    aspect-ratio: 402 / 360;
    height: auto;
  }

  &__system_title {
    @include maxWidth($tablet-s) {
      display: block;
    }
  }

  &__video_slots {
    &_game {
      aspect-ratio: 1/1;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__images {
      margin-top: 107px;
      gap: 39px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      @include maxWidth($tablet-s) {
        gap: 22px;
        margin-top: 48px;
      }

      @include maxWidth($mobile-xl) {
        grid-template-columns: 1fr 1fr;
        row-gap: 26px;
        column-gap: 22px;
      }

      @include maxWidth($mobile-xl) {
        margin-top: 38px;
      }

      & a {
        overflow: hidden;
        display: block;
        border-radius: 30px;
      }
    }
  }

  &__animation {
    &_title,
    &_video_slots_title {
      opacity: 0;
      transform: translateY(450px);
      animation: title_1993_animation;
      animation-duration: 0.8s;
      animation-delay: 0.2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    &_first_computer {
      animation: first_computer_animation;
      animation-duration: 0.8s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    &_second_computer {
      animation: second_computer_animation;
      animation-duration: 0.8s;
      animation-delay: 0.2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    &_video_slots {
      &_first_game,
      &_second_game,
      &_third_game,
      &_fourth_game {
        display: flex;
        justify-content: center;
        opacity: 0.1;
        transform: translateX(100vw);
        animation: game_1993_animation;
        animation-duration: 0.9s;
        animation-delay: 0;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
      }

      &_second_game {
        animation-delay: 0.2s;
      }

      &_third_game {
        animation-delay: 0.4s;
      }

      &_fourth_game {
        animation-delay: 0.6s;
      }
    }
  }

  &__image_block {
    display: flex;
    justify-content: center;
  }
}

.blue_title {
  color: $blue-3;
}

.full_height {
  margin: 150px 0;
}

.company_founded_image {
  aspect-ratio: 749 / 390;
  height: auto;
  width: 100%;
}

@keyframes game_1993_animation {
  from {
    transform: translateX(100vw);
    opacity: 0.1;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes second_computer_animation {
  from {
    transform: translate(-100px, 500px);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes first_computer_animation {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes title_1993_animation {
  from {
    transform: translateY(450px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
