@import './assets/styles/mixins';

.section {
  gap: 52px;

  &__title_block {
    margin-bottom: 64px;

    @include maxWidth($mobile-xl) {
      margin-bottom: 38px;
    }
  }

  &__map_block {
    overflow: hidden;
    width: fit-content;
    margin: 150px auto;

    & h3 {
      padding-left: 24px;

      @include maxWidth($mobile-xl) {
        padding-left: 25px;
        text-align: left;
      }
    }

    &__image {
      transform: translateY(-36px);
      aspect-ratio: 551 / 617;
      height: auto;
      width: 100%;

      @include maxWidth($tablet-l) {
        transform: translateY(0);
        margin-top: 24px;
      }

      &__container {
        width: fit-content;
        position: relative;
        z-index: -1;
      }
    }
  }

  &__map {
    width: fit-content;
    position: relative;
  }
}

.blue_title {
  color: $blue-3;
}

.full_height {
  margin: 150px 0;
}

.map_mark {
  position: absolute;
  top: 7%;
  right: 5%;
  width: 18%;

  &_animation {
    opacity: 1;
    animation: map_mark_animation 0.7s ease 0.5s forwards;
    transform: translateY(-250%);

    @include maxWidth($tablet-l) {
      top: 13%;
    }

    // @include maxWidth($mobile-l) {
    //   top: 12%;
    // }
  }
}

@keyframes map_mark_animation {
  from {
    transform: translateY(-250%);
  }
  to {
    transform: translateY(100%);
  }
}

.machines_banner {
  aspect-ratio: 833 / 440;
  width: 100%;
  height: auto;
}
