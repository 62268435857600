@import './assets/styles/mixins';

.list {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 45px;
  transform: translateX(-150%);
  transition: transform 0.3s ease-in-out;

  &--second {
    transform: translateX(-100%);
  }

  @include maxWidth($mobile-xl) {
    gap: 24px;
  }

  & li {
    flex-shrink: 0;

    @include maxWidth($mobile-xl) {
      height: 24px;
      & span {
        height: 100% !important;
      }
    }
  }

  &__item {
    &_image {
      height: 100% !important;
      object-fit: contain;
    }
  }
}
